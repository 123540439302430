import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

function startAnimation(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      // For 85
      const progressBarRight85 = entry.target.querySelector('.percent-85-right .percent-85');
      progressBarRight85.style.animation = 'loading-50 1.3s linear forwards';
      const progressBarLeft85 = entry.target.querySelector('.percent-85-left .percent-85');
      progressBarLeft85.style.animation = 'loading-35 0.8s linear forwards 1.3s';

      // For 85-2
      const progressBarRight852 = entry.target.querySelector('.percent-85-2-right .percent-85-2');
      progressBarRight852.style.animation = 'loading-50 1.3s linear forwards';
      const progressBarLeft852 = entry.target.querySelector('.percent-85-2-left .percent-85-2');
      progressBarLeft852.style.animation = 'loading-35 0.8s linear forwards 1.3s';

      // For 90
      const progressBarRight90 = entry.target.querySelector('.percent-90-right .percent-90');
      progressBarRight90.style.animation = 'loading-50 1.3s linear forwards';
      const progressBarLeft90 = entry.target.querySelector('.percent-90-left .percent-90');
      progressBarLeft90.style.animation = 'loading-40 0.8s linear forwards 1.3s';

      // For 60
      const progressBarRight60 = entry.target.querySelector('.percent-60-right .percent-60');
      progressBarRight60.style.animation = 'loading-50 1.3s linear forwards';
      const progressBarLeft60 = entry.target.querySelector('.percent-60-left .percent-60');
      progressBarLeft60.style.animation = 'loading-10 0.8s linear forwards 1.3s';

      // For 602
      const progressBarRight602 = entry.target.querySelector('.percent-60-2-right .percent-60-2');
      progressBarRight602.style.animation = 'loading-50 1.3s linear forwards';
      const progressBarLeft602 = entry.target.querySelector('.percent-60-2-left .percent-60-2');
      progressBarLeft60.style.animation = 'loading-10 0.8s linear forwards 1.3s';

      // For 80
      const progressBarRight80 = entry.target.querySelector('.percent-80-right .percent-80');
      progressBarRight80.style.animation = 'loading-50 1.3s linear forwards';
      const progressBarLeft80 = entry.target.querySelector('.percent-80-left .percent-80');
      progressBarLeft80.style.animation = 'loading-30 0.8s linear forwards 1.3s';

      // For 30
      const progressBarRight30 = entry.target.querySelector('.percent-30-right .percent-30');
      progressBarRight30.style.animation = 'loading-30 1.3s linear forwards';
    } else {
      // Reset the animation when leaving the view
      // For 90
      const progressBarRight90 = entry.target.querySelector('.percent-90-right .percent-90');
      progressBarRight90.style.animation = '';
      const progressBarLeft90 = entry.target.querySelector('.percent-90-left .percent-90');
      progressBarLeft90.style.animation = '';

      // For 85
      const progressBarRight85 = entry.target.querySelector('.percent-85-right .percent-85');
      progressBarRight85.style.animation = '';
      const progressBarLeft85 = entry.target.querySelector('.percent-85-left .percent-85');
      progressBarLeft85.style.animation = '';

      // For 852
      const progressBarRight852 = entry.target.querySelector('.percent-85-2-right .percent-85-2');
      progressBarRight852.style.animation = '';
      const progressBarLeft852 = entry.target.querySelector('.percent-85-2-left .percent-85-2');
      progressBarLeft852.style.animation = '';

      // For 80
      const progressBarRight80 = entry.target.querySelector('.percent-80-right .percent-80');
      progressBarRight80.style.animation = '';
      const progressBarLeft80 = entry.target.querySelector('.percent-80-left .percent-80');
      progressBarLeft80.style.animation = '';

      // For 60
      const progressBarRight60 = entry.target.querySelector('.percent-60-right .percent-60');
      progressBarRight60.style.animation = '';
      const progressBarLeft60 = entry.target.querySelector('.percent-60-left .percent-60');
      progressBarLeft60.style.animation = '';

      // For 602
      const progressBarRight602 = entry.target.querySelector('.percent-60-2-right .percent-60-2');
      progressBarRight602.style.animation = '';
      const progressBarLeft602 = entry.target.querySelector('.percent-60-2-left .percent-60-2');
      progressBarLeft602.style.animation = '';

      // For 30
      const progressBarRight30 = entry.target.querySelector('.percent-30-right .percent-30');
      progressBarRight30.style.animation = '';
    }
  });
}

const observer = new IntersectionObserver(startAnimation);

const skillProgressElements = document.querySelectorAll('.skill-progress-wrapper');

skillProgressElements.forEach(element => {
  observer.observe(element);
});




